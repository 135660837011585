import Validator from "./Validator";

class InArray extends Validator {
    allowed = null;
    
    constructor(context, allowed) {
        super(context);
        this.allowed = allowed;
    }

    checkValidity = (values) => {
        if (!this.allowed.includes(values[this.context])) {
            return {
                isValid: false,
                errors: {
                    isNotAllowed: `Field ${this.context} should be set to a supported value: ${this.allowed.join(", ")}`,
                }
            };
        }

        return {
            isValid: true,
            errors: {},
        };
    }
}

export default InArray;
