import Validator from "./Validator";

class HasKey extends Validator {
    subKey = null;

    constructor(context, subKey) {
        super(context);
        this.subKey = subKey;
    }

    checkValidity = (values) => {
        const key = values[this.context];
        if (key && !key[this.subKey]) {
            return {
                isValid: false,
                errors: {
                    hasNoKey: `Field ${this.context} should have a key ${this.subKey}`,
                }
            };
        }

        return {
            isValid: true,
            errors: {},
        };
    }
}

export default HasKey;
