class Validator {
    context = "";

    constructor(context) {
        this.context = context;
    }

    checkValidity = () => {
        return {
            isValid: true,
            errors: {},
        };
    }

    isValid = (values) => {
        const check = this.checkValidity(values);
        return {
            isValid: check.isValid,
            errors: check.isValid ? null : {
                [this.context]: check.errors,
            }
        };
    }
}

export default Validator;
