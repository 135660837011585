import Validator from "./Validator";

class Unique extends Validator {
    existsCallback = null;

    constructor(context, existsCallback) {
        super(context);
        this.existsCallback = existsCallback;
    }

    checkValidity = (values) => {
        const value = values[this.context];
        if (value) {
            const exists = this.existsCallback(value);
            if (exists) {
                return {
                    isValid: false,
                    errors: {
                        isDuplicate: `Unique ${this.context} should be provided`,
                    }
                };
            }
        }

        return {
            isValid: true,
            errors: {},
        };
    }
}

export default Unique;
