import React from "react";
import {
    Toolbar,
    ToolbarGroup,
    Button,
    ButtonGroup,
    ToolbarExpander,
    Dropdown,
    Menu,
    MenuItem,
} from "@plesk/ui-library";

const templateMenu = (handler, elements) => (
    <Menu>
        {Array.from(elements, s => (
            <MenuItem key={s[0]} onClick={() => handler(s[0])}>
                {s[0]}
            </MenuItem>
        ))}
    </Menu>
);

const snippetMenu = (handler, elements) => (
    <Menu>
        {Array.from(elements, s => (
            <MenuItem key={s[1].name} onClick={() => handler(s[0])}>
                {s[1].name}
            </MenuItem>
        ))}
    </Menu>
);

const Menubar = (props) => {
    const {
        snippets,
        templates,
        menuHandler,
        exportHandler,
        snippetHandler,
        convertHandler,
        configHandler,
        version,
    } = { ...props };
    return (
        <Toolbar style={{ marginBottom: 0 }}>
            <ToolbarGroup title="New">
                <Dropdown menu={templateMenu(menuHandler, templates)}>New</Dropdown>
                <Dropdown menu={snippetMenu(snippetHandler, snippets)}>Snippets</Dropdown>
            </ToolbarGroup>
            <ToolbarExpander />
            <ToolbarGroup title="Utils">
                <ButtonGroup>
                    <Button onToggle={exportHandler}>Export</Button>
                    <Button onToggle={convertHandler}>HTML2Markdown</Button>
                    <Button onToggle={configHandler}>Config</Button>
                    <Button disabled>{`Editor v${version}`}</Button>
                </ButtonGroup>
            </ToolbarGroup>
        </Toolbar>
    );
};

export default Menubar;
