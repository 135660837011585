import Validator from "./Validator";

class NotEmpty extends Validator {
    checkValidity = (values) => {
        if (!(this.context in values) || !values[this.context]) {
            return {
                isValid: false,
                errors: {
                    isEmpty: `Field ${this.context} should not be empty`,
                }
            };
        }

        return {
            isValid: true,
            errors: {},
        };
    }
}

export default NotEmpty;
