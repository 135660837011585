import Validator from "./Validator";

class NotANumber extends Validator {
    checkValidity = (values) => {
        if (isNaN(parseInt(values[this.context], 10))) {
            return {
                isValid: false,
                errors: {
                    notNumeric: `Field ${this.context} should be a numeric value`,
                }
            };
        }

        return {
            isValid: true,
            errors: {},
        };
    }
}

export default NotANumber;
