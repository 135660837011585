import React, { Component } from "react";
import {
    Form,
    FormFieldText,
    List,
    ListAction,
    ListActions,
    Section,
} from "@plesk/ui-library";

class TemplateList extends Component {
    state = {
        errors: null,
    }

    validateForm = (values) => {
        const { templates } = { ...this.props };
        let valid = true;
        let errors = {
            name: {},
        };

        if (!values.name) {
            errors.name.isEmpty = "Name should be filled";
            valid = false;
        }

        if (values.name && templates.get(values.name)) {
            errors.name.conflict = "Name should be unique";
            valid = false;
        }

        return { valid, errors };
    }

    handleAddTemplate = (template) => {
        const { changeHandler, templates } = { ...this.props };
        const { valid, errors } = { ...this.validateForm(template) };
        this.setState({ errors: null });

        if (!valid) {
            this.setState({ errors });
            return;
        }

        let handle = {
            text: template.text
                .replaceAll("\\t", "\t")
                .replaceAll("\\n", "\n"),
        };

        templates.set(template.name, handle);
        changeHandler();
    }

    handleRemoveTemplate = (name) => {
        const { changeHandler, templates } = { ...this.props };
        templates.delete(name);
        changeHandler();
    }

    render() {
        const { errors } = { ...this.state };
        const { templates } = { ...this.props };
        const templateColumns = [{
            key: "name",
            title: "Name",
        }, {
            key: "text",
            title: "Text",
        }, {
            key: "actions",
            type: "actions",
            render: (line) => (
                <ListActions>
                    <ListAction
                        icon={"cross-mark"}
                        primary
                        onClick={() => this.handleRemoveTemplate(line.name)}
                    >
                        {"Remove"}
                    </ListAction>
                </ListActions>
            )
        }];
        
        const templatesData = Array.from(templates, t => ({
            name: t[0],
            text: t[1].text.replaceAll("\t", "\\t").replaceAll("\n", "\\n"),
        }));
        
        return (
            <Section title="Templates" collapsed collapsible>
                <List columns={templateColumns} data={templatesData} />
                <Form
                    onSubmit={this.handleAddTemplate}
                    applyButton={{ children: "Add" }}
                    submitButton={false}
                    cancelButton={false}
                    errors={errors}
                >
                    <FormFieldText name="name" label="Name" size="fill" required />
                    <FormFieldText name="text" label="Text" size="fill" required />
                </Form>
            </Section>
        );
    }
}

export default TemplateList;
