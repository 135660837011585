const Decorate = "decorate";
const Export = "export";
const Snippet = "snippet";
const Prepend = "prepend";
const PrependEach = "prependeach";
const RemoveEach = "removeeach";
const Replace = "replace";
const Uppercase = "uppercase";

class Config {
    shortcuts = new Map();
    snippets = new Map();
    templates = new Map();

    constructor() {
        this.loadFromState();
    }

    getHandler = (event) => {
        if (event.altKey) {
        // No shortcuts use Alt, we stop here, as not to break Compose and alternative layouts
            return undefined;
        }

        for (let s of this.shortcuts) {
            let { ctrl, shift, key } = { ...s[1].keys };
            ctrl = !!ctrl;
            shift = !!shift;
            if (ctrl === event.ctrlKey && shift === event.shiftKey && key.toUpperCase() === event.key.toUpperCase()) {
                return s[1];
            }
        }

        return undefined;
    }

    getSnippets = () => this.snippets;
    getShortcuts = () => this.shortcuts;
    getTemplates = () => this.templates;

    loadDefaults = () => {
        this.templates = new Map();
        this.templates.set("Symptom/Solution", {
            text: "## Symptoms\n\n\n## Cause\n\n\n::: resolution\n## Resolution\n\n\n:::\n",
        });
        this.templates.set("Question/Answer", {
            text: "## Question\n\n\n## Answer\n\n\n",
        });

        this.snippets = new Map();
        this.snippets.set(0, {
            name: "UserVoice",
            text: "This feature is not yet implemented in Plesk UI, leave a feature request for it [on our UserVoice](https://plesk.uservoice.com/)",
        });
        this.snippets.set(1, {
            name: "SSH Connect",
            text: "Connect to the server via [SSH](https://support.plesk.com/hc/en-us/articles/115000172834)",
        });
        this.snippets.set(2, {
            name: "RDP Connect",
            text: "Connect to the server via [RDP](https://support.plesk.com/hc/en-us/articles/360000471413)",
        });
        this.snippets.set(3, {
            name: "Plesk Login",
            text: "[Log into Plesk](https://support.plesk.com/hc/en-us/articles/213413369)",
        });
        this.snippets.set(4, {
            name: "Plesk DB Backup",
            text: "[Create Plesk database backup](https://support.plesk.com/hc/en-us/articles/213904125)",
        });
        this.snippets.set(5, {
            name: "Plesk DB Access",
            text: "[Access Plesk database](https://support.plesk.com/hc/en-us/articles/213928465)",
        });
        this.snippets.set(6, {
            name: "Edit File",
            text: "[Edit file](https://support.plesk.com/hc/en-us/articles/360001084114)",
        });
        this.snippets.set(7, {
            name: "Public IPv4",
            text: "203.0.113.2",
        });
        this.snippets.set(8, {
            name: "Private IPv4",
            text: "192.0.2.2",
        });
        this.snippets.set(9, {
            name: "Picture Placeholder",
            text: "![Placeholder](assets/placeholder.png)",
        });

        this.shortcuts = new Map();
        this.shortcuts.set("error", {
            keys: { ctrl: true, key: "O" },
            type: Prepend,
            text: "PLESK_ERROR: ",
        });
        this.shortcuts.set("warning", {
            keys: { ctrl: true, key: "D" },
            type: Prepend,
            text: "PLESK_WARN: ",
        });
        this.shortcuts.set("info", {
            keys: { ctrl: true, shift: true, key: "J" },
            type: Prepend,
            text: "PLESK_INFO: ",
        });
        this.shortcuts.set("configtext", {
            keys: { ctrl: true, key: "J" },
            type: Prepend,
            text: "CONFIG_TEXT: ",
        });
        this.shortcuts.set("link", {
            keys: { ctrl: true, shift: true, key: "H" },
            type: Decorate,
            left: "[",
            right: "](https://example.com/)",
        });
        this.shortcuts.set("ulist", {
            keys: { ctrl: true, shift: true, key: "E" },
            type: Prepend,
            text: "* ",
        });
        this.shortcuts.set("olist", {
            keys: { ctrl: true, key: "E" },
            type: Prepend,
            text: "1. ",
        });
        this.shortcuts.set("internal", {
            keys: { ctrl: true, shift: true, key: "I" },
            type: Decorate,
            left: "::: internal\n",
            right: "\n:::\n",
        });
        this.shortcuts.set("spoiler", {
            keys: { ctrl: true, key: "G" },
            type: Decorate,
            left: "::: spoiler Name\n",
            right: "\n:::\n",
        });
        this.shortcuts.set("winsql", {
            keys: { ctrl: true, shift: true, key: "K" },
            type: Prepend,
            text: "MYSQL_WIN: ",
        });
        this.shortcuts.set("linsql", {
            keys: { ctrl: true, shift: true, key: "L" },
            type: Prepend,
            text: "MYSQL_LIN: ",
        });
        this.shortcuts.set("bold", {
            keys: { ctrl: true, key: "B" },
            type: Decorate,
            left: "**",
            right: "**",
        });
        this.shortcuts.set("italic", {
            keys: { ctrl: true, key: "I" },
            type: Decorate,
            left: "_",
            right: "_",
        });
        this.shortcuts.set("monospace", {
            keys: { ctrl: true, key: "M" },
            type: Decorate,
            left: "`",
            right: "`",
        });
        this.shortcuts.set("cmd", {
            keys: { ctrl: true, key: "K" },
            type: Prepend,
            text: "C:\\> ",
        });
        this.shortcuts.set("bash", {
            keys: { ctrl: true, key: "L" },
            type: Prepend,
            text: "\\# ",
        });
        this.shortcuts.set("pwsh", {
            keys: { ctrl: true, key: "P" },
            type: Prepend,
            text: "PS ",
        });
        this.shortcuts.set("unindent", {
            keys: { shift: true, key: "Tab" },
            type: RemoveEach,
            text: "\t",
        });
        this.shortcuts.set("newpara", {
            keys: { shift: true, key: "Enter" },
            type: Replace,
            text: "\n\n",
        });
        this.shortcuts.set("indent", {
            keys: { key: "Tab" },
            type: PrependEach,
            text: "\t",
        });
        this.shortcuts.set("uppercase", {
            keys: { ctrl: true, key: "U" },
            type: Uppercase,
        });
        this.shortcuts.set("export", {
            keys: { ctrl: true, key: "S" },
            type: Export,
        });

        Array.from(Array(7).keys()).forEach((k) => {
            if (k === 0) {
                return;
            }

            this.shortcuts.set(`h${k}`, {
                keys: { ctrl: true, shift: true, key: k.toString() },
                type: Prepend,
                text: `${"#".repeat(k)} `,
            });
        });

        for (let s of this.snippets) {
            if (s[0] > 9) {
                continue;
            }

            this.shortcuts.set(`snippet#${s[0]}`, {
                keys: { ctrl: true, key: s[0].toString() },
                type: Snippet,
                id: s[0],
            });
        }
    }

    loadFromState = () => {
        const cf = localStorage.getItem("config");
        if (!cf) {
            this.loadDefaults();
            return;
        }

        try {
            this.import(cf);
        } catch (e) {
            console.error(`Could not parse stored configuration: ${e}`);
            this.loadDefaults();
        }
    }

    saveToState = () => {
        localStorage.setItem("config", this.export());
    }

    import = (cf) => {
        const pcf = JSON.parse(cf, (_, v) => (
            v && v instanceof Object && v.type === "Map"
                ? new Map(v.value)
                : v
        ));
        this.shortcuts = pcf.shortcuts;
        this.snippets = pcf.snippets;
        this.templates = pcf.templates;
        this.saveToState();
    }

    export = () => {
        return JSON.stringify({
            shortcuts: this.shortcuts,
            snippets: this.snippets,
            templates: this.templates,
        }, (_, v) => v instanceof Map ? {
            type: "Map",
            value: Array.from(v.entries()),
        } : v);
    }
}

export default Config;
export {
    Decorate,
    Export,
    Prepend,
    PrependEach,
    RemoveEach,
    Replace,
    Snippet,
    Uppercase,
};
