import React, { Component } from "react";
import {
    Form,
    FormFieldText,
    List,
    ListAction,
    ListActions,
    Section,
} from "@plesk/ui-library";
import ValidateMany from "../../validators/ValidateMany";
import NotANumber from "../../validators/NotANumber";
import NotEmpty from "../../validators/NotEmpty";
import Unique from "../../validators/Unique";

class SnippetList extends Component {
    state = {
        errors: null,
    }

    validateForm = (values) => {
        const { snippets } = { ...this.props };
        return ValidateMany([
            new NotEmpty("id"),
            new NotEmpty("name"),
            new Unique("id", (v) => snippets.get(parseInt(v, 10))),
            new NotANumber("id"),
        ], values);
    }

    handleAddSnippet = (snippet) => {
        const { changeHandler, snippets } = { ...this.props };
        const { isValid, errors } = { ...this.validateForm(snippet) };
        this.setState({ errors: null });

        if (!isValid) {
            this.setState({ errors });
            return;
        }

        let handle = {
            name: snippet.name,
            text: snippet.text
                .replaceAll("\\t", "\t")
                .replaceAll("\\n", "\n"),
        };

        snippets.set(parseInt(snippet.id, 10), handle);
        changeHandler();
    }

    handleRemoveSnippet = (id) => {
        const { changeHandler, snippets } = { ...this.props };
        snippets.delete(id);
        changeHandler();
    }

    render() {
        const { errors } = { ...this.state };
        const { snippets } = { ...this.props };
        const snippetColumns = [{
            key: "id",
            title: "#",
        }, {
            key: "name",
            title: "Name",
        }, {
            key: "text",
            title: "Text",
        }, {
            key: "actions",
            type: "actions",
            render: (line) => (
                <ListActions>
                    <ListAction
                        icon={"cross-mark"}
                        primary
                        onClick={() => this.handleRemoveSnippet(line.id)}
                    >
                        {"Remove"}
                    </ListAction>
                </ListActions>
            )
        }];
        
        const snippetsData = Array.from(snippets, s => ({
            id: s[0],
            ...s[1],
        }));
        
        return (
            <Section title="Snippets" collapsed collapsible>
                <List columns={snippetColumns} data={snippetsData} />
                <Form
                    applyButton={{ children: "Add" }}
                    submitButton={false}
                    cancelButton={false}
                    onSubmit={this.handleAddSnippet}
                    errors={errors}
                >
                    <FormFieldText name="id" label="#" size="fill" required />
                    <FormFieldText name="name" label="Name" size="fill" required />
                    <FormFieldText name="text" label="Text" size="fill" required />
                </Form>
            </Section>
        );
    }
}

export default SnippetList;
