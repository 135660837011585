const findSymbol = (ref, symbol, position, boundary = 0, toLeft = true) => {
    let caret = position;
    if (toLeft) {
        while (ref.value[caret] !== symbol && caret >= boundary) {
            caret -= 1;
        }
    } else {
        while (ref.value[caret] !== symbol && caret <= boundary) {
            caret += 1;
        }
    }

    return caret;
};

const textAreaProps = (textArea) => {
    return {
        selStart: textArea.selectionStart,
        selEnd: textArea.selectionEnd,
        value: textArea.value,
    };
};

class Editor {
    textArea = null;

    constructor(ref, doc) {
        this.textArea = ref;
        this.document = doc;
    }

    all = () => (this.textArea.current ? this.textArea.current.value : "")

    insert = (text) => {
        this.textArea.current.focus();
        this.document.execCommand("insertText", false, text);
    }

    prepend = (text) => {
        const { selStart, selEnd } = { ...textAreaProps(this.textArea.current) };
        let select = findSymbol(this.textArea.current, "\n", selStart);
        while (this.textArea.current.value[select + 1] === "\t") {
            select++;
        }

        this.textArea.current.setSelectionRange(select + 1, select + 1);
        this.insert(text);

        this.textArea.current.setSelectionRange(selStart + text.length, selEnd + text.length);
    }

    prependMany = (text) => {
        const { selStart, selEnd, value } = { ...textAreaProps(this.textArea.current) };
        // Start from the next line
        // Edge case: whole document is selected and we will need to prepend first line as well
        const selectFrom = findSymbol(this.textArea.current, "\n", selStart - 1) + 1;
        const targetText = value
            .slice(selectFrom, selEnd)
            .split("\n")
            .map(line => text + line)
            .join("\n");

        this.textArea.current.setSelectionRange(selectFrom, selEnd);
        this.insert(targetText);

        const shift = this.textArea.current.value.length - value.length;
        this.textArea.current.setSelectionRange(selStart + text.length, selEnd + shift);
    }

    replace = (text) => {
        const { value } = { ...textAreaProps(this.textArea.current) };
        this.textArea.current.setSelectionRange(0, value.length);
        this.insert(text);
    }

    decorate = (prepend, append) => {
        const { selStart, selEnd, value } = { ...textAreaProps(this.textArea.current) };
        const text = prepend + value.slice(selStart, selEnd) + append;

        this.insert(text);
        this.textArea.current.setSelectionRange(selStart + prepend.length, selEnd + prepend.length);
    }

    remove = (text, many = false) => {
        const { selStart, selEnd } = { ...textAreaProps(this.textArea.current) };
        const selectFrom = findSymbol(this.textArea.current, "\n", selStart - 1) + 1;
        const selectTo = findSymbol(this.textArea.current, "\n", selEnd, this.textArea.current.value.length - 1, false);

        const targetText = this.textArea.current.value.slice(selectFrom, selectTo)
            .replace(new RegExp(`^${text}`, many ? "gm" : ""), "");

        const shift = this.textArea.current.value.slice(selectFrom, selectTo).length - targetText.length;
        this.textArea.current.setSelectionRange(selectFrom, selectTo);
        this.insert(targetText);

        const targetFrom = (selStart - text.length > 0) ? selStart - text.length : 0;
        const targetTo = (selEnd - shift > 0) ? selEnd - shift : 0;
        this.textArea.current.setSelectionRange(targetFrom, targetTo);
    }

    removeMany = (text) => {
        this.remove(text, true);
    }

    selection = () => {
        const { selStart, selEnd, value } = { ...textAreaProps(this.textArea.current) };
        return value.slice(selStart, selEnd);
    }
}

export default Editor;
