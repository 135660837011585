const mergeObjects = (objects) => {
    let res = {};

    objects.forEach(object => {
        for (let property in object) {
            if (property in res) {
                if (object[property] instanceof Object) {
                    res[property] = mergeObjects([res[property], object[property]]);
                }
            } else {
                res[property] = object[property];
            }
        }
    });
    
    return res;
};

const ValidateMany = (validators, values) => {
    const validated = validators.map(v => {
        return v.isValid(values);
    });

    return {
        isValid: validated.every(v => v.isValid),
        errors: mergeObjects(validated.map(v => v.errors)),
    };
};

export default ValidateMany;
