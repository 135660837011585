import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    Button,
    Input,
    Section,
    StatusMessage,
} from "@plesk/ui-library";

import Shortcuts from "./lists/Shortcuts";
import Snippets from "./lists/Snippets";
import Templates from "./lists/Templates";
import Config from "../utils/Config";

class Configurator extends Component {
    textRef = React.createRef();
    config = null;
    shortcuts = null;
    snippets = null;
    templates = null;

    constructor(props) {
        super(props);

        this.state = {
            config: props.config,
            hasChanges: false,
        };

        this.config = props.config;
        this.shortcuts = this.config.getShortcuts();
        this.snippets = this.config.getSnippets();
        this.templates = this.config.getTemplates();
    }

    render() {
        const { config, hasChanges } = { ...this.state };
        return (
            <>
                <Section title="Save configuration" collapsible>
                    {hasChanges ? (
                        <StatusMessage intent="warning">
                            There are unsaved changes: they will be lost on reload
                        </StatusMessage>
                    ) : null}
                    <Button
                        onToggle={() => {
                            this.config.saveToState();
                            this.setState({ hasChanges: false });
                        }}
                        intent="primary"
                    >
                        Save changes
                    </Button>
                </Section>
                <Section title="Share saved configuration" collapsed collapsible>
                    <Button
                        onToggle={() => {
                            this.config.import(atob(this.textRef.current.value));
                            this.setState({ hasChanges: true });
                        }}
                    >
                        Import
                    </Button>
                    {` `}
                    <Input
                        size="xl"
                        value={btoa(this.config.export())}
                        innerRef={this.textRef}
                    />
                    {` `}
                    <Button
                        onToggle={() => {
                            this.config.loadDefaults();
                            this.setState({ hasChanges: false });
                        }}
                    >
                        Defaults
                    </Button>
                </Section>
                <Templates
                    templates={config.templates}
                    changeHandler={() => {
                        this.setState({ hasChanges: true });
                        this.forceUpdate();
                    }}
                />
                <Snippets
                    snippets={config.snippets}
                    changeHandler={() => {
                        this.setState({ hasChanges: true });
                        this.forceUpdate();
                    }}
                />
                <Shortcuts
                    shortcuts={config.shortcuts}
                    snippets={config.snippets}
                    changeHandler={() => {
                        this.setState({ hasChanges: true });
                        this.forceUpdate();
                    }}
                />
            </>
        );
    }
}

Configurator.propTypes = {
    config: PropTypes.shape({
        type: PropTypes.instanceOf(Config),
    }),
};

export default Configurator;
