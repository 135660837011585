import React, { Component } from "react";
import {
    Form,
    FormFieldText,
    FormFieldRadioButtons,
    Input,
    Label,
    List,
    ListAction,
    ListActions,
    Section,
    Switch,
    Text,
    Tooltip,
} from "@plesk/ui-library";
import {
    Decorate,
    Export,
    Prepend,
    PrependEach,
    RemoveEach,
    Replace,
    Snippet,
    Uppercase,
} from "../../utils/Config";
import getHtml from "../../utils/Converter";
import FormFieldShortcut from "../FormFieldShortcut";
import ValidateMany from "../../validators/ValidateMany";
import NotEmpty from "../../validators/NotEmpty";
import InArray from "../../validators/InArray";
import HasKey from "../../validators/HasKey";
import Unique from "../../validators/Unique";

const replaceSpecial = (text) => text.replaceAll("\t", "\\t").replaceAll("\n", "\\n");
const replaceSpecialBack = (text) => text.replaceAll("\\t", "\t").replaceAll("\\n", "\n");

const renderText = (handle, text, snippets) => {
    return replaceSpecial(getHtml(describeAction(handle, text, snippets)));
};
  
const describeAction = (handle, text, snippets) => {
    switch (handle.type) {
    case Decorate:
        return `${handle.left}${text}${handle.right}`;
    case Export:
        return `(Export)`;
    case Prepend:
        return `${handle.text}${text}`;
    case PrependEach:
        return `${handle.text}${text}\n${handle.text}${text}`;
    case RemoveEach:
        return `(Remove ${handle.text})`;
    case Replace:
        return `${handle.text}`;
    case Uppercase:
        return `${text.toUpperCase()}`;
    case Snippet:
        return snippets.get(handle.id)
            ? snippets.get(handle.id).text
            : `Snippet #${handle.id} (undefined!)`;
    default:
        console.error(`Unknown shortcut type: ${JSON.stringify(handle.type)}`);
        break;
    }
};

class ShortcutList extends Component {
    textRef = React.createRef();

    state = {
        errors: null,
        render: false,
        text: "Placeholder",
    }

    validateForm = (values) => {
        const { shortcuts } = { ...this.props };

        return ValidateMany([
            new NotEmpty("name"),
            new Unique("name", (n) => shortcuts.get(n)),
            new NotEmpty("keys"),
            new NotEmpty("type"),
            new Unique("keys", (k) => Array.from(shortcuts)
                .find(h => h[1].keys.ctrl === k.ctrl
                    && h[1].keys.shift === k.shift
                    && h[1].keys.key === k.key)
            ),
            new HasKey("keys", "key"),
            new InArray("type", [
                Decorate,
                Export,
                Prepend,
                PrependEach,
                RemoveEach,
                Replace,
                Uppercase,
                Snippet,
            ]),
        ], values);
    }

    handleAddShortcut = (shortcut) => {
        const { isValid, errors } = { ...this.validateForm(shortcut) };
        const { changeHandler, shortcuts } = { ...this.props };
        this.setState({
            errors: null,
            values: { ...shortcut },
        });

        if (!isValid) {
            this.setState({ errors });
            return;
        }

        let handle = {
            keys: shortcut.keys,
            type: shortcut.type,
        };

        switch (shortcut.type) {
        case Decorate:
            handle.left = replaceSpecialBack(shortcut.left);
            handle.right = replaceSpecialBack(shortcut.right);
            break;
        case Snippet:
            handle.id = parseInt(shortcut.id, 10);
            break;
        case Prepend:
            handle.text = replaceSpecialBack(shortcut.prepend);
            break;
        case PrependEach:
            handle.text = replaceSpecialBack(shortcut.prependeach);
            break;
        case RemoveEach:
            handle.text = replaceSpecialBack(shortcut.removeeach);
            break;
        case Replace:
            handle.text = replaceSpecialBack(shortcut.replace);
            break;
        case Export:
        case Uppercase:
            break;
        default:
            // Checked in validator
            return;
        }

        shortcuts.set(shortcut.name, handle);
        changeHandler();
    }

    handleRemoveShortcut = (name) => {
        const { changeHandler, shortcuts } = { ...this.props };

        shortcuts.delete(name);
        changeHandler();
    }

    render() {
        const { render, text, errors } = { ...this.state };
        const { snippets, shortcuts } = { ...this.props };

        const shortcutColumns = [{
            key: "name",
            title: "Name",
            align: "left",
            width: 60,
        }, {
            key: "keys",
            title: "Keys",
            align: "left",
            width: 160,
        }, {
            key: "action",
            title: "Action",
            align: "left",
            truncate: true,
        }, {
            key: "actions",
            type: "actions",
            align: "right",
            render: (line) => (
                <ListActions>
                    <ListAction
                        icon={"cross-mark"}
                        primary
                        onClick={() => this.handleRemoveShortcut(line.name.props.title)}
                    >
                        {"Remove"}
                    </ListAction>
                </ListActions>
            )
        }];
        
        const shortcutsData = Array.from(shortcuts, s => {
            const { keys } = { ...s[1] };
            const name = s[0];
        
            const k = (
                <>
                    <Label intent={keys.ctrl ? "success" : "inactive"}>Ctrl</Label>
                    {` `}
                    <Label intent={keys.shift ? "success" : "inactive"}>Shift</Label>
                    {` `}
                    <Label intent="success">{keys.key}</Label>
                </>
            );
        
            const n = (
                <Tooltip title={name}>
                    <Text>{name}</Text>
                </Tooltip>
            );
        
            const view = render
                ? renderText(s[1], text, snippets)
                : replaceSpecial(describeAction(s[1], text, snippets));
        
            const action = (
                <Tooltip title={view}>
                    <Text>{view}</Text>
                </Tooltip>
            );
            return {
                name: n,
                keys: k,
                action,
            };
        });
        
        return (
            <Section title="Shortcuts" collapsible>
                <Switch
                    checked={render}
                    onChange={() => this.setState({ render: !render })}
                >
                As HTML
                </Switch>
                {` `}
                <Input
                    size={"xl"}
                    placeholder={text}
                    innerRef={this.textRef}
                    onChange={() => this.setState({ text: this.textRef.current.value })}
                />
                <List columns={shortcutColumns} data={shortcutsData} />
                <Form
                    applyButton={{ children: "Add" }}
                    submitButton={false}
                    cancelButton={false}
                    errors={errors}
                    onSubmit={this.handleAddShortcut}
                >
                    <FormFieldText
                        name="name"
                        label="Name"
                        size="fill"
                        description="Unique name of the shortcut"
                        required
                    />
                    <FormFieldShortcut
                        name="keys"
                        label="Shortcut"
                        description="Focus this field and input a desired unique shortcut"
                        required
                    />
                    <FormFieldRadioButtons
                        label="Type"
                        name="type"
                        required
                        options={[{
                            value: Decorate,
                            label: "Decorate",
                            indentFields: [
                                <FormFieldText
                                    key="decorateLeft"
                                    name={"left"}
                                    label={null}
                                    description="Decorate selected text from left side"
                                    required
                                />,
                                <FormFieldText
                                    key="decorateRight"
                                    name={"right"}
                                    label={null}
                                    description="Decorate selected text from right side"
                                    required
                                />,
                            ],
                        }, {
                            value: Prepend,
                            label: "Prepend",
                            indentFields: [
                                <FormFieldText
                                    key="prepend"
                                    name={"prepend"}
                                    label={null}
                                    description="Prepend line with text"
                                    required
                                />,
                            ],
                        }, {
                            value: PrependEach,
                            label: "Prepend each",
                            indentFields: [
                                <FormFieldText
                                    key="prepend"
                                    name={"prependeach"}
                                    label={null}
                                    description="Prepend each line in selection with text"
                                    required
                                />,
                            ],
                        }, {
                            value: RemoveEach,
                            label: "Remove each",
                            indentFields: [
                                <FormFieldText
                                    key="removeeach"
                                    name={"removeeach"}
                                    label={null}
                                    description="Remove symbol from the beginning of all selected lines"
                                    required
                                />,
                            ],
                        }, {
                            value: Replace,
                            label: "Replace",
                            indentFields: [
                                <FormFieldText
                                    key="replace"
                                    name={"replace"}
                                    label={null}
                                    description="Replace selected text with value"
                                    required
                                />,
                            ],
                        }, {
                            value: Snippet,
                            label: "Snippet",
                            indentFields: [
                                <FormFieldText
                                    key="snippet"
                                    name={"id"}
                                    label={null}
                                    description="Apply snippet with selected ID"
                                    required
                                />,
                            ],
                        }, {
                            value: Uppercase,
                            label: "Uppercase",
                            description: "Change selected text case to uppercase",
                        }]}
                    />
                </Form>
            </Section>
        );
    }
}

export default ShortcutList;
