import TurndownService from "turndown";

let t = null;

const fixBreak = () => ({
    filter: "br",
    replacement: (_, node, options) => {
        if (node.previousElementSibling && node.previousElementSibling.nodeName === "BR") {
            return `${options.br}\\\n`;
        }
        return `${options.br}\n`;
    },
});

const fixList = () => ({
    filter: "li",
    replacement: (content, node, options) => {
        const parent = node.parentNode;
        let prefix = `${options.bulletListMarker}   `;
        if (parent.nodeName === "OL") {
            const start = parent.getAttribute("start");
            const index = Array.prototype.indexOf.call(parent.children, node);
            prefix = `${start ? Number(start) + index : index + 1}. `;
        }

        const c = content
            .replace(/^\n+/, "") // remove leading newlines
            .replace(/\n+$/, "\n") // replace trailing newlines with just a single one
            .replace(/\n/gm, "\n    "); // indent

        const nextLine = node.nextSibling && !/\n$/.test(c) ? "\n" : "";
        return `${prefix}${c}${nextLine}`;
    },
});

const spoiler = (turndown) => ({
    filter: (node) => node.className === "accordion-container",
    replacement: (_, node) => {
        const header = node.firstChild.textContent;
        const content = turndown.turndown(node.children[1]);

        return `::: spoiler ${header}\n${content}\n:::\n\n`;
    },
});

const applicable = (turndown) => ({
    filter: (node) => node.className === "applies_to",
    replacement: (_, node) => `::: applicable\n${turndown.turndown(node)}\n:::\n\n`,
});

const resolution = (turndown) => ({
    filter: (node) => node.className === "resolution",
    replacement: (_, node) => `::: resolution\n${turndown.turndown(node)}\n:::\n\n`,
});

const lightcase = () => ({
    filter: (node) => node.dataset.rel === "lightcase" && node.children.length,
    replacement: (_, node) => `![${node.children[0].alt}](min${node.children[0].src})`,
});

const fixLang = () => ({
    filter: (node, options) => (
        options.codeBlockStyle === "fenced"
        && node.nodeName === "PRE"
        && node.firstChild
        && node.firstChild.nodeName === "CODE"
    ),
    replacement: (_, node, options) => {
        const language = node.firstChild.className || "";
        return `\n\n${options.fence}${language}\n${node.firstChild.textContent}\n${options.fence}\n\n`;
    },
});

const internal = (turndown) => ({
    filter: (node) => node.className === "internaldata",
    replacement: (_, node) => `::: internal \n${turndown.turndown(node)}\n:::\n\n`,
});

const toc = () => ({
    filter: (node) => node.className === "table-of-contents",
    replacement: () => "${toc}",
});

const tocLink = () => ({
    filter: (node) => (
        node.nodeName === "A"
        && node.className === "header-anchor"
    ),
    replacement: () => "",
});

const newT = () => {
    const ts = new TurndownService({
        codeBlockStyle: "fenced",
        headingStyle: "atx",
    });

    return ts
        .addRule("fixBreak", fixBreak())
        .addRule("fixList", fixList())
        .addRule("spoiler", spoiler(ts))
        .addRule("applicable", applicable(ts))
        .addRule("lightcase", lightcase())
        .addRule("lang-fix", fixLang())
        .addRule("internal", internal(ts))
        .addRule("resolution", resolution(ts))
        .addRule("toc", toc())
        .addRule("toclink", tocLink(ts));
};

const getMarkdown = (html) => {
    if (!t) {
        t = newT();
    }

    const h = html.replace(/"\/hc\/article_attachments/g, "\"https://support.plesk.com/hc/article_attachments");
    return t.turndown(h);
};

export default getMarkdown;
