import React from "react";
import PropTypes from "prop-types";
import { FormField, Input } from "@plesk/ui-library";

const handleInput = (e, callback, allowAlt) => {
    e.preventDefault();
    e.stopPropagation();

    const newVal = {
        ctrl: e.ctrlKey,
        shift: e.shiftKey,
        alt: allowAlt ? e.altKey : false,
        key: !(["Ctrl", "Control", "Shift", "Alt"].includes(e.key)) ? e.key : null,
    };

    console.log(newVal);
    callback(newVal);
};

const valueToString = (value) => {
    const keys = value
        ? [
            value.ctrl ? "Ctrl" : null,
            value.shift ? "Shift" : null,
            value.alt ? "Alt" : null,
            value.key ? value.key : null,
        ]
        : [];

    return keys.filter(k => k).join(", ");
};

const FormFieldShortcut = ({
    allowAlt,
    size,
    ...props
}) => (
    <FormField {...props}>
        {({ getId, getName, setValue, getValue }) => (
            <Input
                id={getId()}
                name={getName()}
                onKeyDown={(e) => handleInput(e, setValue, allowAlt)}
                size={size}
                value={valueToString(getValue(""))}
            />
        )}
    </FormField>
);

FormFieldShortcut.propTypes = {
    allowAlt: PropTypes.bool,
    baseClassName: PropTypes.string,
    className: PropTypes.string,
    defaultValue: PropTypes.object,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    fullDescription: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
    size: PropTypes.oneOf(["sm", "md", "lg", "xl", "fill"]),
};

FormFieldShortcut.defaultProps = {
    allowAlt: false,
    baseClassName: "pul-form-field",
    className: undefined,
    defaultValue: undefined,
    description: undefined,
    disabled: undefined,
    fullDescription: undefined,
    label: undefined,
    name: undefined,
    required: false,
    size: undefined,
};

export default FormFieldShortcut;
