import {
    Decorate,
    Export,
    Prepend,
    PrependEach,
    RemoveEach,
    Replace,
    Uppercase,
} from "./Config";

class Decorator {
    editor = null;

    export = null;

    constructor(editor, exportFn) {
        this.editor = editor;
        this.export = exportFn;
    }

    handleShortcut = (shortcut) => {
        switch (shortcut.type) {
        case Decorate:
            this.editor.decorate(shortcut.left, shortcut.right);
            break;
        case Export:
            this.export();
            break;
        case Prepend:
            this.editor.prepend(shortcut.text);
            break;
        case PrependEach:
            this.editor.prependMany(shortcut.text);
            break;
        case RemoveEach:
            this.editor.removeMany(shortcut.text);
            break;
        case Replace:
            this.editor.insert(shortcut.text);
            break;
        case Uppercase:
            this.editor.replace(this.editor.selection().toUpperCase());
            break;
        default:
            console.error(`Unknown shortcut type: ${JSON.stringify(shortcut)}`);
            break;
        }
    }
}

export default Decorator;
