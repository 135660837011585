import MarkdownIt from "markdown-it";
import markdownitContainer from "markdown-it-container";
import markdownitAnchor from "markdown-it-anchor";
import markdownitToc from "markdown-it-toc-done-right";

let m;

const newM = () => {
    const mt = new MarkdownIt({
        highlight: (str, lang) => (
            `<pre class="${lang.toLowerCase()}"><code class="${lang.toLowerCase()}">${mt.utils.escapeHtml(str)}</code></pre>`
        ),
        langPrefix: "",
        xhtmlOut: true,
        breaks: true,
    })
        .use(markdownitContainer, "spoiler", {
            validate: (params) => params.trim().match(/^spoiler\s+(.*)$/),
            render: (tokens, idx) => {
                const md = tokens[idx].info.trim().match(/^spoiler\s+(.*)$/);
                if (tokens[idx].nesting === 1) {
                    return `<div class="accordion-container"><div class="accordion">${mt.utils.escapeHtml(md[1])}</div><div class="texto">\n`;
                }
                return "</div></div>\n";
            },
        })
        .use(markdownitContainer, "applicable", {
            validate: (params) => params.trim().match(/^applicable/),
            render: (tokens, idx) => {
                if (tokens[idx].nesting === 1) {
                    return "<div class=\"applies_to\">\n";
                }
                return "</div>\n";
            },
        })
        .use(markdownitContainer, "internal", {
            validate: (params) => params.trim().match(/^internal/),
            render: (tokens, idx) => {
                if (tokens[idx].nesting === 1) {
                    return "<div class=\"internaldata\">\n";
                }
                return "</div>\n";
            },
        })
        .use(markdownitContainer, "resolution", {
            validate: (params) => params.trim().match(/^resolution/),
            render: (tokens, idx) => {
                if (tokens[idx].nesting === 1) {
                    return "<div class=\"resolution\">\n";
                }
                return "</div>\n";
            },
        })
        .use(markdownitAnchor, {
            level: 3,
            permalink: true,
            permalinkBefore: true,
            permalinkSymbol: "#",
        })
        .use(markdownitToc, {
            level: 3,
            listType: "ul",
        });

    const linkRender = mt.renderer.rules.link_open || ((tokens, idx, options, env, self) => (
        self.renderToken(tokens, idx, options)
    ));

    mt.renderer.rules.link_open = (tokens, idx, options, env, self) => {
        const token = tokens[idx];
        const hrefIdx = token.attrIndex("href");
        if (token.attrs[hrefIdx][1].startsWith("http")) {
            tokens[idx].attrPush(["target", "_blank"]);
            tokens[idx].attrPush(["rel", "noopener noreferrer"]);
        }

        return linkRender(tokens, idx, options, env, self);
    };

    const imageRender = mt.renderer.rules.image;
    mt.renderer.rules.image = (tokens, idx, options, env, self) => {
        const token = tokens[idx];
        const srcIndex = token.attrIndex("src");
        if (token.attrs[srcIndex][1].startsWith("min+")) {
            const src = token.attrs[srcIndex][1].substr(4);
            const alt = token.content;
            return `<a href="${src}" data-rel="lightcase"><img src="${src}" alt="${alt}" width="355" height="200" /></a>`;
        }

        return imageRender(tokens, idx, options, env, self);
    };

    return mt;
};

const getHtml = (md) => {
    if (!m) {
        m = newM();
    }

    return m.render(md)
        .replace(/<p>C:&gt;/g, "<p>C:\\&gt;")
        .replace(/<p># /g, "<p class=\"bash\"># ")
        .replace(/<p>C:\\&gt;/g, "<p class=\"winshell\">")
        .replace(/<p>PS /g, "<p class=\"powershell\">PS ")
        .replace(/<p>PLESK_ERROR:/g, "<p class=\"pleskerr\">")
        .replace(/<p>PLESK_WARN:/g, "<p class=\"pleskwarn\">")
        .replace(/<p>PLESK_INFO:/g, "<p class=\"pleskinfo\">")
        .replace(/<p>MYSQL_LIN:/g, "<p class=\"bash\">")
        .replace(/<p>MYSQL_WIN:/g, "<p class=\"winshell\">")
        .replace(/<p>CONFIG_TEXT:/g, "<p class=\"configtext\">")
        .replace(/<p>Note: /g, "<p class=\"note\"><strong>Note:</strong> ")
        .replace(/<p><strong>Note:<\/strong> /g, "<p class=\"note\"><strong>Note:</strong> ")
        .replace(/<p>Warning: /g, "<p class=\"warning\"><strong>Warning:</strong> ")
        .replace(/<p><strong>Warning:<\/strong> /g, "<p class=\"warning\"><strong>Warning:</strong> ");
};

export default getHtml;
