import React, { Component } from "react";
import { Drawer, Toaster } from "@plesk/ui-library";
import SplitPane from "react-split-pane";
import copy from "copy-to-clipboard";
import { Beforeunload } from "react-beforeunload";
import Menubar from "./components/Menubar";
import getMarkdown from "./utils/Parser";
import Config, { Snippet } from "./utils/Config";
import Decorator from "./utils/Decorator";
import getHtml from "./utils/Converter";
import Editor from "./utils/Editor";
import Configurator from "./components/Configurator";

const version = "2.0";
const build = "50";

class App extends Component {
    constructor(props) {
        super(props);
        this.toasterRef = React.createRef();
        this.textArea = React.createRef();
        this.editor = new Editor(this.textArea, document);
        this.decorator = new Decorator(this.editor, this.exportHandler);
        this.config = new Config();

        this.state = {
            configOpen: false,
            text: "",
        };
    }

    updateSource = (e) => {
        this.setState({ text: e.target.value });
    }

    renderMarkdown = () => ({
        __html: getHtml(this.editor.all()),
    });

    menuHandler = (name) => {
        const template = this.config.getTemplates().get(name);
        if (template) {
            this.editor.replace(template.text);
        }
    }

    snippetHandler = (id) => {
        const snippet = this.config.getSnippets().get(id);
        if (snippet) {
            this.editor.insert(snippet.text);
        }
    }

    exportHandler = () => {
        copy(getHtml(this.editor.all()));
        this.toasterRef.current.add({
            intent: "success",
            message: "Copied HTML to the clipboard. You can use it further in Zendesk",
        });
    }

    convertHandler = () => {
        this.editor.replace(getMarkdown(this.editor.all()));
    }

    configHandler = () => {
        const { configOpen } = { ...this.state };
        this.setState({ configOpen: !configOpen });
    }

    shortcutHandler = (e) => {
        const h = this.config.getHandler(e);
        if (h) {
            e.preventDefault();
            e.stopPropagation();

            if (h.type === Snippet) {
                this.snippetHandler(h.id);
            } else {
                this.decorator.handleShortcut(h);
            }
        }
    }

    render() {
        const { text, configOpen } = { ...this.state };
        return (
            <Beforeunload onBeforeunload={() => (this.editor.all() ? "" : false)}>
                <Menubar
                    exportHandler={this.exportHandler}
                    convertHandler={this.convertHandler}
                    configHandler={this.configHandler}
                    menuHandler={this.menuHandler}
                    snippetHandler={this.snippetHandler}
                    snippets={this.config.getSnippets() ?? new Map()}
                    templates={this.config.getTemplates() ?? new Map()}
                    version={version}
                    build={build}
                />
                <SplitPane
                    split="vertical"
                    defaultSize="33vw"
                >
                    <div>
                        <textarea
                            className="textarea-main"
                            onKeyDown={this.shortcutHandler}
                            onChange={this.updateSource}
                            defaultValue={text}
                            ref={this.textArea}
                        />
                    </div>
                    <div
                        className="preview"
                        dangerouslySetInnerHTML={this.renderMarkdown()}
                    />
                </SplitPane>
                <Drawer
                    title={"Configuration"}
                    isOpen={configOpen}
                    onClose={this.configHandler}
                >
                    <Configurator config={this.config} />
                </Drawer>
                <Toaster ref={this.toasterRef} maxToastsNumber={1} />
            </Beforeunload>
        );
    }
}

export default App;
